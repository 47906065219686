import { doc, writeBatch, getDoc } from "firebase/firestore";
import { auth, db } from "../App";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { UserInfo } from "../types/UserInfo";
import { UserName } from "../types/UserName";

export class UserController {
  static async register(
    email: string,
    password: string,
    username: string
  ): Promise<User> {
    const usernameRef = doc(
      UserName.datapoint(UserName.collectionPath()),
      username
    );

    const usernameDoc = await getDoc(usernameRef);

    if (usernameDoc.exists() && usernameDoc.data()?.taken) {
      throw new Error("Username taken");
    }

    let userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const batch = writeBatch(db);

    const userId: string = userCredential.user.uid;

    const userRef = doc(UserInfo.datapoint(UserInfo.collectionPath()), userId);

    batch.update(userRef, { username: username });
    batch.set(usernameRef, { taken: true });

    await batch.commit();

    return userCredential.user;
  }

  static async login(email: string, password: string): Promise<User> {
    let userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    let user = userCredential.user;
    return user;
  }
}
