import { db } from "../App";
import {
  DocumentData,
  QueryDocumentSnapshot,
  CollectionReference,
  collection,
} from "firebase/firestore";

export class UserName {
  readonly handle?: string;
  taken: boolean;

  constructor(handle: string, taken: boolean) {
    this.handle = handle;
    this.taken = taken;
  }

  static collectionPath(): string {
    return "taken_user_names";
  }

  static converter = {
    toFirestore(data: UserName): DocumentData {
      return { taken: data.taken };
    },

    fromFirestore(snapshot: QueryDocumentSnapshot): UserName {
      const documentData = snapshot.data();

      const handle = snapshot.id;
      const taken = documentData.taken;

      const data = new UserName(handle, taken);
      return data;
    },
  };

  static datapoint = (
    collectionPath: string
  ): CollectionReference<UserName> => {
    return collection(db, collectionPath).withConverter(UserName.converter);
  };
}
