import React from "react";
import { PostInfo } from "../types/Post";

import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";

type PostCardProps = { post: PostInfo };

const PostCard = ({ post }: PostCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "30rem",
        borderRadius: "0.5rem",
        border: "2px solid darkgrey",
      }}
    >
      <CardContent>
        <Link to={`post/${post.id}`}>
          <span
            style={{
              fontSize: "1.5rem",
            }}
          >
            {post.title}
          </span>
          <OpenInNew
            fontSize="medium"
            sx={{ position: "relative", top: "0.3rem" }}
          ></OpenInNew>
        </Link>
      </CardContent>
      {post.imageUrl && (
        <div style={{ margin: "1rem 1rem 0rem 1rem" }}>
          <CardMedia
            component="img"
            image={post.imageUrl}
            sx={{
              objectFit: "cover",
              maxHeight: "20rem",
            }}
          />
        </div>
      )}
      <CardContent>
        {/* {post.tags?.map((tag, idx) => <Chip label={tag} key={idx} />)} */}
        <Typography>{post.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default PostCard;
