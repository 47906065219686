import React, { useState } from "react";
import { signOut, User } from "firebase/auth";
import { auth } from "../App";
import { useMediaQuery, Menu, MenuItem } from "@mui/material";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { PersonAdd, Logout, Login } from "@mui/icons-material";
import PostDialog from "./Post/PostDialog";
import LogIn from "./Login/Login";
import RegisterView from "./Login/Register";
import NavBarMobile from "./NavBar/NavBarMobile";
import "../App.css";
import NavBarDesktop from "./NavBar/NavBarDesktop";

type RootProps = {
  currentUser: User | null;
};

const Root = ({ currentUser }: RootProps) => {
  const [showPostDialog, setShowPostDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | any>(null);

  const nav = useNavigate();

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const handleInput = (e: React.KeyboardEvent) => {
    if (e.code === "Enter") {
      if (searchText.trim() !== "") {
        nav(`/search/${searchText}`);
        nav(0);
      }
    }
  };

  return (
    <div>
      {isDesktop ? (
        <NavBarDesktop
          searchText={searchText}
          setSearchText={setSearchText}
          handleInput={handleInput}
          currentUser={currentUser}
          setShowLoginDialog={setShowLoginDialog}
          setShowPostDialog={setShowPostDialog}
          setShowRegisterDialog={setShowRegisterDialog}
        />
      ) : (
        <NavBarMobile
          setMobileMenuAnchor={setMobileMenuAnchor}
          setShowPostDialog={setShowPostDialog}
          searchText={searchText}
          handleSearchInput={handleInput}
          setSearchText={setSearchText}
          currentUser={currentUser}
        />
      )}

      <div style={{ margin: "1rem 1rem 0rem 1rem" }}>
        <Outlet />
        <PostDialog
          open={showPostDialog}
          setOpen={setShowPostDialog}
          currentUser={currentUser}
        />
        <LogIn open={showLoginDialog} setOpen={setShowLoginDialog} />
        <RegisterView
          open={showRegisterDialog}
          setOpen={setShowRegisterDialog}
        />
        <Menu
          open={mobileMenuAnchor !== null}
          onClose={() => setMobileMenuAnchor(null)}
          anchorEl={mobileMenuAnchor}
        >
          {currentUser ? (
            <div>
              <MenuItem
                onClick={() => {
                  setMobileMenuAnchor(null);
                  signOut(auth);
                }}
              >
                <div className="row-mobile">
                  <Logout />
                  Log Out
                </div>
              </MenuItem>
            </div>
          ) : (
            <div>
              <MenuItem
                onClick={() => {
                  setMobileMenuAnchor(null);
                  setShowRegisterDialog(true);
                }}
              >
                <div className="row-mobile">
                  <PersonAdd />
                  Create Account
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMobileMenuAnchor(null);
                  setShowLoginDialog(true);
                }}
              >
                <div className="row-mobile">
                  <Login />
                  Log In
                </div>
              </MenuItem>
            </div>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default Root;
