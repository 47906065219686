import React, { useEffect, useState } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  getDoc,
  query,
  collection,
  orderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { ThemeProvider, createTheme } from "@mui/material";
import Root from "./pages/Root";
import UserView from "./pages/User";
import { UserName } from "./types/UserName";
import { PostInfo } from "./types/Post";
import PostView from "./pages/Post/PostView";
import HomeView from "./pages/HomeView";
import PostsSearchView from "./pages/Post/PostsSearchView";
import { PostController } from "./controllers/PostController";
import { Comment } from "./types/Comment";
import { UserInfo } from "./types/UserInfo";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

if (process.env.REACT_APP_ENVIRONMENT === "local") {
  connectFirestoreEmulator(db, "localhost", 8082);
  connectFunctionsEmulator(functions, "localhost", 5003);
  connectAuthEmulator(auth, "http://localhost:9400");
  connectStorageEmulator(storage, "localhost", 9198);
}

const router = (currentUser: User | null) =>
  createBrowserRouter([
    {
      path: "/",
      element: <Root currentUser={currentUser} />,
      errorElement: <div>404 Not Found</div>,
      children: [
        {
          path: "/",
          element: <HomeView />,
          // loader: async () => {
          //   const res = await PostController.getRecentPosts(() => {});
          //   return res;
          // },
        },
        {
          path: "/u/:username",
          element: <UserView currentUser={currentUser}></UserView>,
          errorElement: <div>404 Not Found</div>,
          loader: async ({ params }) => {
            const username = params.username;
            const usernameRef = doc(
              UserName.datapoint(UserName.collectionPath()),
              username,
            );
            const usernameDoc = await getDoc(usernameRef);
            if (!usernameDoc.exists() || !usernameDoc.data().taken) {
              throw new Response("Not Found", { status: 404 });
            }
            return usernameDoc.data();
          },
        },
        {
          path: "/post/:postId",
          element: <PostView currentUser={currentUser} />,
          errorElement: <div>404 Not Found</div>,
          loader: async ({ params }) => {
            const postId = params.postId;
            const postRef = doc(
              PostInfo.datapoint(PostInfo.collectionPath()),
              postId,
            );
            const postDoc = await getDoc(postRef);
            if (!postDoc.exists()) {
              throw new Response("Not Found", { status: 404 });
            }

            const commentsRef = Comment.datapoint(
              Comment.collectionPath(postId!),
            );

            const commentQuery = query(
              commentsRef,
              orderBy("timestamp", "desc"),
              limit(25),
            );
            const commentsSnapshot = await getDocs(commentQuery);

            const commentsRes: Comment[] = [];
            commentsSnapshot.forEach((doc) => {
              commentsRes.push(doc.data());
            });

            const newCommentsRes = await Promise.all(
              commentsRes.map(async (comment) => {
                const userRef = doc(
                  UserInfo.datapoint(UserInfo.collectionPath()),
                  comment.authorId,
                );

                const usernameDoc = await getDoc(userRef);

                return {
                  ...comment,
                  authorDisplayName: usernameDoc.data()?.username,
                };
              }),
            );

            console.log(newCommentsRes);

            return { post: postDoc.data(), comments: newCommentsRes };
          },
        },
        {
          path: "/search/:tag",
          element: <PostsSearchView />,
          errorElement: <div>404 Not Found</div>,
        },
      ],
    },
  ]);

// https://zenoo.github.io/mui-theme-creator/#Appbar
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00675D",
      light: "#33857D",
      dark: "#004841",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e5e5e5",
    },
  },
});

const App = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router(currentUser)}></RouterProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
