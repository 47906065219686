import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { UserInfo } from "../types/UserInfo";
import { query, where, getDocs } from "firebase/firestore";
import { UserName } from "../types/UserName";
import { User } from "firebase/auth";

type UserProps = {
  currentUser: User | null;
};

const UserView = ({ currentUser }: UserProps) => {
  const profileName = useLoaderData() as UserName;
  const [userData, setUserData] = useState<UserInfo | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const q = query(
        UserInfo.datapoint(UserInfo.collectionPath()),
        where("username", "==", profileName.handle)
      );

      const querySnapshot = await getDocs(q);
      setUserData(querySnapshot.docs[0].data());
    })();
  }, []);

  return (
    <div>
      {userData?.userId}
      <br />
      {currentUser?.uid} <br />
      {currentUser && currentUser.uid === userData?.userId
        ? "It's me"
        : "It's not me"}
      <br />
      {userData?.bio && userData?.bio !== "" ? userData.bio : "No bio yet"}
    </div>
  );
};

export default UserView;
