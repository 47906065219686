import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PostInfo } from "../../types/Post";
import { PostController } from "../../controllers/PostController";
import { DocumentSnapshot } from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-responsive-masonry";
import { LinearProgress, Typography, useMediaQuery } from "@mui/material";
import PostCard from "../PostCard";

const PostsSearchView = () => {
  const { tag } = useParams();
  const [lastSeenPost, setLastSeenPost] = useState<
    DocumentSnapshot | undefined
  >(undefined);

  const [posts, setPosts] = useState<PostInfo[] | undefined>(undefined);

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const fetchMorePosts = async (tag: string) => {
    const res = await PostController.searchPostsByTag(
      setLastSeenPost,
      tag,
      lastSeenPost,
    );
    setPosts([...posts!, ...res]);
  };

  useMemo(async () => {
    if (tag) {
      const res = await PostController.searchPostsByTag(setLastSeenPost, tag);
      setPosts(res);
    }
  }, [tag]);

  return (
    <div>
      <Typography variant="h4">Search results for "{tag}":</Typography>
      {posts === undefined ? (
        <LinearProgress sx={{ width: "20rem" }} />
      ) : (
        <InfiniteScroll
          dataLength={posts.length}
          next={() => fetchMorePosts(tag!)}
          hasMore={true}
          loader={<h4>loading...</h4>}
        >
          <Masonry columnsCount={isDesktop ? 4 : 1}>
            {posts.map((post) => (
              <div style={{ margin: "15px" }}>
                <PostCard post={post}></PostCard>
              </div>
            ))}
          </Masonry>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default PostsSearchView;
