import { httpsCallable } from "firebase/functions";
import { PostRequest } from "../types/requests/PostRequest";
import { functions, storage } from "../App";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  DocumentSnapshot,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { PostInfo } from "../types/Post";
import { PostDeleteRequest } from "../types/requests/PostDeleteRequest";
import { CommentRequest } from "../types/requests/CommentRequest";

const { v4: uuidv4 } = require("uuid");

export class PostController {
  postId: string;

  constructor(postId: string) {
    this.postId = postId;
  }

  static async create(req: PostRequest) {
    const postCreate = httpsCallable(functions, "requestPost");

    const postId = await postCreate(req);
    return postId;
  }

  static async delete(req: PostDeleteRequest) {
    const postDelete = httpsCallable(functions, "requestDelete");

    await postDelete(req);
    return;
  }

  static async uploadToStorage(file: File) {
    const postRef = ref(storage, `post_images/${uuidv4()}`);
    await uploadBytes(postRef, file).then(async (snapshot) => {
      console.log("uploaded!");
    });

    return await getDownloadURL(postRef);
  }

  static async getRecentPosts(
    setLastSeen: React.Dispatch<
      React.SetStateAction<DocumentSnapshot | undefined>
    >,
    lastSeen?: DocumentSnapshot,
  ): Promise<PostInfo[]> {
    const postsRef = PostInfo.datapoint(PostInfo.collectionPath());
    let q;
    if (lastSeen) {
      q = query(
        postsRef,
        orderBy("timestamp", "desc"),
        startAfter(lastSeen),
        limit(25),
      );
    } else {
      q = query(postsRef, orderBy("timestamp", "desc"), limit(25));
    }

    const querySnapshot = await getDocs(q);

    setLastSeen(querySnapshot.docs[querySnapshot.docs.length - 1]);
    const res: PostInfo[] = [];

    querySnapshot.forEach((doc) => {
      res.push(doc.data());
    });

    return res;
  }

  static async searchPostsByTag(
    setLastSeen: React.Dispatch<
      React.SetStateAction<DocumentSnapshot | undefined>
    >,
    tag: string,
    lastSeen?: DocumentSnapshot,
  ) {
    const postsRef = PostInfo.datapoint(PostInfo.collectionPath());
    let q;
    if (lastSeen) {
      q = query(
        postsRef,
        startAfter(lastSeen),
        where("tags", "array-contains", tag),
        limit(25),
      );
    } else {
      q = query(postsRef, where("tags", "array-contains", tag), limit(25));
    }

    const querySnapshot = await getDocs(q);

    setLastSeen(querySnapshot.docs[querySnapshot.docs.length - 1]);
    const res: PostInfo[] = [];
    querySnapshot.forEach((doc) => {
      res.push(doc.data());
    });

    return res;
  }

  static async getNumPostsByTag(tag: string) {
    const postsRef = PostInfo.datapoint(PostInfo.collectionPath());

    const q = query(postsRef, where("tags", "array-contains", tag));

    const querySnapshot = await getCountFromServer(q);
    return querySnapshot.data().count;
  }

  static async getNumPosts() {
    const postsRef = PostInfo.datapoint(PostInfo.collectionPath());

    const q = await getCountFromServer(query(postsRef));
    return q.data().count;
  }

  static async createComment(req: CommentRequest) {
    if (req.text.length > 0) {
      const commentCreate = httpsCallable(functions, "requestComment");

      await commentCreate(req);
      return;
    }
  }
}
