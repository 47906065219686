import { AccountCircle, AddCircle, Home, Search } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
} from "@mui/material";
import { User } from "firebase/auth";
import { Link } from "react-router-dom";

type NavBarMobileProps = {
  searchText: string;
  handleSearchInput: any;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  currentUser: User | null;
  setMobileMenuAnchor: React.Dispatch<React.SetStateAction<any>>;
  setShowPostDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBarMobile = ({
  searchText,
  handleSearchInput,
  setSearchText,
  currentUser,
  setMobileMenuAnchor,
  setShowPostDialog,
}: NavBarMobileProps) => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Link to={"/"}>
          <IconButton sx={{ color: "white" }}>
            <Home fontSize="large" />
          </IconButton>
        </Link>
        <div className="text-field-toolbar-background-mobile">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onKeyDown={(e) => handleSearchInput(e)}
            onChange={(e) => setSearchText(e.target.value.trim())}
          />
        </div>
        <div>
          {currentUser && (
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setShowPostDialog(true)}
              size="large"
            >
              <AddCircle fontSize="large" />
            </IconButton>
          )}

          <IconButton
            size="large"
            sx={{ color: "white" }}
            onClick={(e) => setMobileMenuAnchor(e.target)}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarMobile;
