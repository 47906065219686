import {
  AddCircle,
  Home,
  Login,
  Logout,
  PersonAdd,
  Search,
} from "@mui/icons-material";
import {
  AppBar,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
} from "@mui/material";
import { User, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../App";

type NavBarDesktopProps = {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleInput: any;
  currentUser: User | null;
  setShowPostDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoginDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegisterDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBarDesktop = ({
  searchText,
  setSearchText,
  handleInput,
  currentUser,
  setShowLoginDialog,
  setShowRegisterDialog,
  setShowPostDialog,
}: NavBarDesktopProps) => {
  const nav = useNavigate();

  return (
    <AppBar
      position="static"
      color="primary"
      // sx={{ backgroundColor: "transparent" }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <div className="toolbar-desktop">
          <Link to={"/"}>
            <IconButton sx={{ color: "white" }}>
              <Home fontSize="large" />
            </IconButton>
          </Link>
          <div className="text-field-toolbar-background">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ borderRadius: "3rem", textTransform: "none" }}
                      disabled={searchText.trim() === ""}
                      onClick={() => {
                        nav(`/search/${searchText}`);
                        nav(0);
                      }}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
              value={searchText}
              onKeyDown={(e) => handleInput(e)}
              onChange={(e) => setSearchText(e.target.value.trim())}
            />
          </div>
        </div>
        {/* <IconButton onClick={() => nav("/")}> </IconButton> */}
        <div className="row">
          {currentUser ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => signOut(auth)}
              >
                <div style={{ display: "flex" }}>
                  <Logout fontSize="medium" sx={{ marginRight: "0.5rem" }} />
                  Log Out
                </div>
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => setShowPostDialog(true)}
              >
                <div style={{ display: "flex" }}>
                  <AddCircle fontSize="medium" sx={{ marginRight: "0.5rem" }} />
                  New Post
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShowRegisterDialog(true)}
              >
                <div style={{ display: "flex" }}>
                  <PersonAdd fontSize="medium" sx={{ marginRight: "0.5rem" }} />
                  Create Account
                </div>
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShowLoginDialog(true)}
              >
                <div style={{ display: "flex" }}>
                  <Login fontSize="medium" sx={{ marginRight: "0.5rem" }} />
                  Log In
                </div>
              </Button>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarDesktop;
