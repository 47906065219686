import { db } from "../App";
import {
  DocumentData,
  QueryDocumentSnapshot,
  CollectionReference,
  collection,
  Timestamp,
} from "firebase/firestore";

export class PostInfo {
  readonly id?: string;
  title: string;
  userId: string;
  imageUrl?: string;
  description?: string;
  timestamp: Timestamp;
  tags?: string[];
  // to-do: figure out comments subcollection

  constructor(
    id: string,
    userId: string,
    title: string,
    timestamp: Timestamp,
    imageUrl?: string,
    description?: string,
    tags?: string[]
  ) {
    this.id = id;
    this.userId = userId;
    this.title = title;
    this.timestamp = timestamp;
    this.imageUrl = imageUrl;
    this.description = description;
    this.tags = tags;
  }

  static collectionPath(): string {
    return "posts";
  }

  static converter = {
    toFirestore(data: PostInfo): DocumentData {
      return {
        userId: data.userId,
        title: data.title,
        imageUrl: data.imageUrl,
        description: data.description,
        timestamp: data.timestamp,
        tags: data.tags,
      };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): PostInfo {
      const documentData = snapshot.data();

      const id = snapshot.id;
      const userId = documentData.userId;
      const title = documentData.title;
      const imageUrl = documentData.imageUrl;
      const description = documentData.description;
      const timestamp = documentData.timestamp;
      const tags = documentData.tags;

      const data = new PostInfo(
        id,
        userId,
        title,
        timestamp,
        imageUrl,
        description,
        tags
      );

      return data;
    },
  };

  static datapoint = (
    collectionPath: string
  ): CollectionReference<PostInfo> => {
    return collection(db, collectionPath).withConverter(PostInfo.converter);
  };
}
