import { User } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { PostInfo } from "../../types/Post";
import { useMediaQuery } from "@mui/material";
import { UserInfo } from "../../types/UserInfo";
import { doc, getDoc } from "firebase/firestore";
import PostViewDesktop from "./PostViewDesktop";
import PostViewMobile from "./PostViewMobile";
import { Comment } from "../../types/Comment";

type PostViewProps = {
  currentUser: User | null;
};

const PostView = ({ currentUser }: PostViewProps) => {
  const [author, setAuthor] = useState<undefined | string>(undefined);
  const { post, comments } = useLoaderData() as {
    post: PostInfo;
    comments: Comment[];
  };

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const findAuthor = async () => {
    setAuthor(undefined);
    const authorRef = doc(
      UserInfo.datapoint(UserInfo.collectionPath()),
      post.userId,
    );

    const result = (await getDoc(authorRef)).data();

    setAuthor(result?.username);
  };

  useEffect(() => {
    console.log(comments);
  }, []);

  useMemo(async () => {
    await findAuthor();
  }, [post.userId]);

  return isDesktop ? (
    <PostViewDesktop
      currentUser={currentUser}
      post={post}
      author={author}
      comments={comments}
    />
  ) : (
    <PostViewMobile
      currentUser={currentUser}
      post={post}
      author={author}
      comments={comments}
    />
  );
};

export default PostView;
