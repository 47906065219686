import React, { useState } from "react";
import { PostInfo } from "../../types/Post";
import { User } from "firebase/auth";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Create } from "@mui/icons-material";
import { Comment } from "../../types/Comment";
import { Timestamp } from "firebase/firestore";
import { PostController } from "../../controllers/PostController";

type PostViewMobileProps = {
  currentUser: User | null;
  post: PostInfo;
  author: string | undefined;
  comments: Comment[];
};

const PostViewMobile = ({
  currentUser,
  post,
  author,
  comments,
}: PostViewMobileProps) => {
  const [comment, setComment] = useState("");
  const [commentFocus, setCommentFocus] = useState(false);
  const [commentPosting, setCommentPosting] = useState(false);
  const [submittedComments, setSubmittedComments] = useState<Comment[]>([]);

  return (
    <div
      className="post-container-mobile"
      style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
    >
      <div
        style={{
          border: "2px solid grey",
          borderRadius: "1.5rem",
          padding: "1rem",
        }}
        className="post-content-container"
      >
        <div>
          <div className="post-title">
            <Typography variant="h4">{post.title}</Typography>
          </div>
          <Typography sx={{}}>
            <i>-{author}</i>
          </Typography>
        </div>
        <div className="post-description">
          {post.description
            ?.split("\n")
            .map((stri, idx) => <Typography key={stri}>{stri}</Typography>)}
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ color: "grey" }}>
            <i>tags: </i>
          </Typography>
          {post.tags && post.tags.length > 0 ? (
            post.tags.map((tag, idx) => <Chip label={tag} key={idx}></Chip>)
          ) : (
            <Typography sx={{ color: "grey" }}>
              <i>none </i>
            </Typography>
          )}
        </div>

        {post.imageUrl && (
          <img
            alt="post"
            src={post.imageUrl}
            style={{ maxWidth: "100%", objectFit: "cover" }}
          />
        )}
      </div>
      <div style={{ padding: "1rem" }}>
        <Typography variant={"h5"}>Comments: </Typography>
        <div>
          {currentUser && (
            <TextField
              label="Leave a comment..."
              onFocus={() => {
                setCommentFocus(true);
              }}
              value={comment}
              fullWidth
              variant="standard"
              sx={{ marginBottom: "1rem" }}
              onChange={(e) => setComment(e.target.value)}
            />
          )}
          {commentFocus && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse",
                gap: "0.5rem",
              }}
            >
              <Button
                variant="contained"
                disabled={comment.length === 0 || commentPosting}
                onClick={async () => {
                  const commentObj = {
                    authorId: currentUser!.uid,
                    parentPostId: post.id!,
                    text: comment,
                    timestamp: Timestamp.now(),
                  };
                  setCommentPosting(true);
                  await PostController.createComment(commentObj);
                  setCommentPosting(false);
                  setComment("");
                  setCommentFocus(false);
                  setSubmittedComments((comments) => [...comments, commentObj]);
                }}
              >
                {commentPosting ? (
                  <CircularProgress
                    variant="indeterminate"
                    sx={{ color: "white" }}
                    size="small"
                  />
                ) : (
                  <>Submit</>
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                // sx={{ color: "black" }}
                // color="black"
                onClick={() => {
                  setComment("");
                  setCommentFocus(false);
                }}
              >
                Cancel
              </Button>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {submittedComments.length > 0 &&
              submittedComments.map((comment) => (
                <div
                  style={{
                    borderRadius: "1.5rem",
                  }}
                >
                  <b>You</b>: {comment.text}
                </div>
              ))}
            {comments.length > 0 &&
              comments.map((comment) => (
                <div
                  style={{
                    borderRadius: "1.5rem",
                  }}
                >
                  {comment.authorId === currentUser?.uid ? (
                    <b>You</b>
                  ) : (
                    <>{comment.authorDisplayName}</>
                  )}
                  : {comment.text}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostViewMobile;
