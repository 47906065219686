import {
  CollectionReference,
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  collection,
} from "firebase/firestore";
import { db } from "../App";

export class Comment {
  readonly id?: string;
  readonly parentPostId: string;
  authorId: string;
  text: string;
  timestamp: Timestamp;
  authorDisplayName?: string;

  constructor(
    id: string,
    parentPost: string,
    authorId: string,
    text: string,
    timestamp: Timestamp,
  ) {
    this.id = id;
    this.authorId = authorId;
    this.parentPostId = parentPost;
    this.text = text;
    this.timestamp = timestamp;
  }

  static collectionPath(parentPostId: string): string {
    return `posts/${parentPostId}/comments`;
  }

  static converter = {
    toFirestore(data: Comment): DocumentData {
      return {
        authorId: data.authorId,
        text: data.text,
        timestamp: data.timestamp,
      };
    },

    fromFirestore(snapshot: QueryDocumentSnapshot): Comment {
      const documentData = snapshot.data();

      const id = snapshot.id;
      const parentPostId = snapshot.ref.path.split("/")[1];
      const authorId = documentData.authorId;
      const text = documentData.text;
      const timestamp = documentData.timestamp;

      const data = new Comment(id, parentPostId, authorId, text, timestamp);
      return data;
    },
  };

  static datapoint = (collectionPath: string): CollectionReference<Comment> => {
    return collection(db, collectionPath).withConverter(Comment.converter);
  };
}
