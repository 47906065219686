import {
  Typography,
  TextField,
  Button,
  Dialog,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { UserController } from "../../controllers/UserController";

type RegisterProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RegisterView = ({ open, setOpen }: RegisterProps) => {
  const [handle, setHandle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const handleClose = () => {
    setOpen(false);
    setError("");
    setHandle("");
    setLoading(false);
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  async function attemptRegister() {
    try {
      if (password !== confirmPassword) {
        setError("Password and confirm password must match");
      } else {
        setLoading(true);
        await UserController.register(email, password, handle);
        setLoading(false);
        handleClose();
      }
    } catch (error: any) {
      if (error.code) {
        setError("Invalid Username Or Password");
      }
    }
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={!isDesktop}>
      <div
        className={
          isDesktop ? "login-dialog-container" : "login-dialog-container-mobile"
        }
      >
        <Typography sx={{ textAlign: "center" }} variant="h4">
          Create Account
        </Typography>
        <div className="login-dialog-input-row">
          <Typography>Username</Typography>
          <TextField
            autoFocus
            value={handle}
            id={"username"}
            onChange={(e) => setHandle(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        <div className="login-dialog-input-row">
          <Typography>Email</Typography>
          <TextField
            value={email}
            id={"email"}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        <div className="login-dialog-input-row">
          <Typography>Password</Typography>
          <TextField
            value={password}
            id={"password"}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        <div className="login-dialog-input-row">
          <Typography>Confirm Password</Typography>
          <TextField
            value={confirmPassword}
            id={"confirmpassword"}
            type={"password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        {error && <div className="login-error">{error}</div>}
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Button variant="contained" onClick={() => attemptRegister()}>
            Create Account
          </Button>
        )}
        <button className="button-outlined" onClick={() => handleClose()}>
          Cancel
        </button>
      </div>
    </Dialog>
  );
};

export default RegisterView;
