import { db } from "../App";
import {
  DocumentData,
  QueryDocumentSnapshot,
  CollectionReference,
  collection,
} from "firebase/firestore";

export class UserInfo {
  readonly userId?: string;
  username: string;
  profileImage?: string;
  bio?: string;

  constructor(
    userId: string,
    username: string,
    profileImage?: string,
    bio?: string
  ) {
    this.userId = userId;
    this.username = username;
    this.profileImage = profileImage;
    this.bio = bio;
  }

  static collectionPath(): string {
    return "users";
  }

  static converter = {
    toFirestore(data: UserInfo): DocumentData {
      return {
        userName: data.username,
        profileImage: data.profileImage,
        bio: data.bio,
      };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserInfo {
      const documentData = snapshot.data();

      const userId = snapshot.id;
      const userName = documentData.username;
      const profileImage = documentData.profileImage;
      const bio = documentData.bio;

      const data = new UserInfo(userId, userName, profileImage, bio);
      return data;
    },
  };

  static datapoint = (
    collectionPath: string
  ): CollectionReference<UserInfo> => {
    return collection(db, collectionPath).withConverter(UserInfo.converter);
  };
}
