import { DocumentSnapshot } from "firebase/firestore";
import React, { useMemo, useState } from "react";
import { PostController } from "../controllers/PostController";
import { PostInfo } from "../types/Post";
import { Typography, LinearProgress, useMediaQuery } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import PostCard from "./PostCard";
import Masonry from "react-responsive-masonry";

const HomeView = () => {
  const [lastSeen, setLastSeen] = useState<DocumentSnapshot | undefined>(
    undefined,
  );
  const [posts, setPosts] = useState<PostInfo[] | undefined>(undefined);

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const fetchMorePosts = async () => {
    const res = await PostController.getRecentPosts(setLastSeen, lastSeen);
    setPosts([...posts!, ...res]);
  };

  useMemo(async () => {
    const res = await PostController.getRecentPosts(setLastSeen);
    setPosts(res);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "2rem", textAlign: "center" }}>
        <Typography>
          We've been friends for 8+ years and have a bit too many inside jokes.
          So I made
        </Typography>
        <Typography variant="h3">The LPS '19 Inside Joke Wiki</Typography>
      </div>

      <div>
        <Typography variant="h4">Recent posts:</Typography>
        {posts === undefined ? (
          <LinearProgress sx={{ width: "20rem" }}></LinearProgress>
        ) : (
          // <div style={{ height: "10rem", overflow: "auto" }} id="scrollableDiv">
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMorePosts}
            hasMore={true}
            loader={<h4>loading...</h4>}
            // scrollableTarget="scrollableDiv"
          >
            <Masonry columnsCount={isDesktop ? 4 : 1}>
              {posts.map((post, idx) => (
                <div key={idx} style={{ margin: "10px" }}>
                  <PostCard post={post}></PostCard>
                </div>
              ))}
            </Masonry>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default HomeView;
