import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { UserController } from "../../controllers/UserController";
import "./Login.css";

type LoginProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const LogIn = ({ open, setOpen }: LoginProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const handleClose = () => {
    setOpen(false);
    setError("");
    setLoading(false);
    setEmail("");
    setPassword("");
  };

  async function attemptLogin() {
    try {
      setLoading(true);
      await UserController.login(email, password);
      setLoading(false);
      handleClose();
    } catch (error: any) {
      if (error.code) {
        setError("Invalid Username Or Password");
      }
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={!isDesktop}>
      <div
        className={
          isDesktop ? "login-dialog-container" : "login-dialog-container-mobile"
        }
      >
        <Typography sx={{ textAlign: "center" }} variant="h4">
          Log In
        </Typography>
        <div className="login-dialog-input-row">
          <Typography>Email</Typography>
          <TextField
            autoFocus
            value={email}
            id={"email"}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        <div className="login-dialog-input-row">
          <Typography>Password</Typography>
          <TextField
            value={password}
            id={"password"}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        {error && <div className="login-error">{error}</div>}
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Button variant="contained" onClick={() => attemptLogin()}>
            Log In
          </Button>
        )}
        <button className="button-outlined" onClick={() => handleClose()}>
          CANCEL
        </button>
      </div>
    </Dialog>
  );
};

export default LogIn;
