import React, { useState } from "react";
import {
  Dialog,
  TextField,
  Chip,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { User } from "firebase/auth";
import { Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import "./Post.css";
import { PostController } from "../../controllers/PostController";
import { PostRequest } from "../../types/requests/PostRequest";
import { useNavigate } from "react-router-dom";

type PostDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: User | null;
};

const PostDialog = ({ open, setOpen, currentUser }: PostDialogProps) => {
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postMedia, setPostMedia] = useState<File | undefined>(undefined);
  const [postTags, setPostTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const isDesktop = useMediaQuery("(min-width: 600px)");

  const nav = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setPostTitle("");
    setPostDescription("");
    setPostMedia(undefined);
    setPostTags([]);
    setCurrentTag("");
    setError("");
  };

  const handleTagKey = (e: React.KeyboardEvent) => {
    if (e.code === "Space") {
      const tagsCopy = [...postTags];
      if (!postTags.includes(currentTag.toLocaleLowerCase())) {
        tagsCopy.push(currentTag.toLocaleLowerCase());
        setPostTags(tagsCopy);
      }
      setCurrentTag("");
    }
  };

  const handleTagDelete = (idx: number) => {
    const tagsCopy = [...postTags];
    tagsCopy.splice(idx, 1);
    setPostTags(tagsCopy);
  };

  const handlePostSubmit = async () => {
    if (currentUser) {
      setLoading(true);
      if (!postTitle) {
        setError("Your post must have a title");
        setLoading(false);
        return;
      }
      let url;
      if (postMedia) {
        url = await PostController.uploadToStorage(postMedia);
      }
      const req: PostRequest = {
        userId: currentUser.uid,
        title: postTitle,
        timeStamp: Timestamp.now(),
        description: postDescription,
        imageUrl: url,
        tags: postTags,
      };
      const postId: any = await PostController.create(req);
      nav(`/post/${postId.data.postId}`);
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth={!isDesktop}
      sx={{
        ".MuiDialog-paper": {
          maxWidth: "none",
        },
      }}
    >
      <div
        className={
          isDesktop ? "post-dialog-container" : "post-dialog-container-mobile"
        }
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Create a New Post
        </Typography>
        <div className="post-dialog-input-row">
          <Typography>Title</Typography>
          <TextField
            autoFocus
            value={postTitle}
            id={"title"}
            onChange={(e) => {
              setError("");
              setPostTitle(e.target.value);
            }}
            sx={{ width: "100%" }}
          />
          {error && <Typography color="error">{error}</Typography>}
        </div>
        <div className="post-dialog-input-row">
          <Typography>Description</Typography>
          <TextField
            value={postDescription}
            id={"description"}
            multiline={true}
            minRows={6}
            onChange={(e) => setPostDescription(e.target.value)}
            sx={{ width: "100%" }}
          />
        </div>
        <div className="post-dialog-upload-image">
          <p>Upload an image (optional):</p>
          <input
            type="file"
            onChange={(e) => {
              if (e.target.files) setPostMedia(e.target.files[0]);
            }}
          ></input>
        </div>
        <div>
          <Typography>Tags (optional):</Typography>

          <div className="post-dialog-tags-list">
            {postTags.map((tag, idx) => (
              <Chip
                // color="primary"
                label={tag}
                key={tag}
                onDelete={() => {
                  handleTagDelete(idx);
                }}
              ></Chip>
            ))}
          </div>
          <TextField
            id="tags"
            value={currentTag}
            onKeyDown={(e) => handleTagKey(e)}
            onChange={(e) => setCurrentTag(e.target.value.trim())}
            sx={{ width: "100%" }}
          />
          <Typography sx={{ fontSize: "0.9rem" }}>
            Enter tags separated by a space (ex: "Catan", "Nogueras", etc.)
          </Typography>
        </div>
        <div
          className={
            isDesktop
              ? "post-dialog-bottom-view"
              : "post-dialog-bottom-view-mobile"
          }
        >
          {loading ? (
            <CircularProgress color="primary" variant="indeterminate" />
          ) : (
            <>
              <button className="button-outlined" onClick={() => handleClose()}>
                Cancel
              </button>
              <Button
                variant="contained"
                onClick={async () => {
                  await handlePostSubmit();
                }}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PostDialog;
